<template>
    <v-row align="center" class="list">
        <v-col cols="12" sm="12">
            <v-data-table :mobile-breakpoint="0" class="iconTable" :headers="headers" :items="arrayPurchases.data"
                disable-pagination :hide-default-footer="true" :loading="fetchingData"
                :loading-text="'Cargando... Espere por favor'" :no-data-text="'datos no disponibles'" item-key="_id"
                hide-default-header>
                <template #header="{ props: { headers } }">
                    <thead class="v-data-table-header" style="background-color: #299F7E;">
                        <tr>
                            <template v-for="header in headers">
                                <th :key="header.value" :class="header.align">
                                    <v-row>
                                        <v-col cols="12">
                                            <h2 style="color: white">
                                                {{ header.text }}
                                            </h2>
                                        </v-col>
                                    </v-row>
                                </th>
                            </template>
                        </tr>
                    </thead>
                </template>
                <template #[`item.fecha`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.created_at }}
                    </td>
                </template>
                <template #[`item.n_polla`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.amount_pollas }}
                    </td>
                </template>
                <template #[`item.cliente`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.user.full_name }}
                    </td>
                </template>
                <template #[`item.n_doc`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{ item.user.document_number }}
                    </td>
                </template>
                <template #[`item.m_pago`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        TARGETA
                    </td>
                </template>
                 <template #[`item.p_polla`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                        {{item.polla.price}}
                    </td>
                </template>
                 <template #[`item.cod_prom`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                       {{item.coupon &&  item.coupon.coupon_code ? item.coupon.coupon_code : ''}}
                    </td>
                </template>
                 <template #[`item.discount`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                       {{item.discount}}
                    </td>
                    
                </template>
                 <template #[`item.subtotal`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                       {{item.subtotal}}
                    </td>
                </template>
                 <template #[`item.total`]="{ item }">
                    <td :style="
                        arrayPurchases.data.indexOf(item) % 2 != 0
                            ? 'background:#75D89F1A!important'
                            : ''
                    ">
                       {{item.total}}
                    </td>
                </template>
            </v-data-table>
        </v-col>

        <v-col cols="12" sm="12">
            <v-row>
                <v-col cols="12" sm="12" class="d-flex justify-end">
                    <v-pagination v-model="page" :length="arrayPurchases.last_page" total-visible="7"
                        next-icon="mdi-menu-right" prev-icon="mdi-menu-left" @input="handlePageChange" circle>
                    </v-pagination>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
    name: "table-ventas",
    props: ['text'],
    watch: {
        text: function (value) {
            this.getFetch();
        },
    },
    data: () => ({
        headers: [
            { text: "Fecha", value: "fecha", width: 150, sortable: false },
            { text: "N°Pollas", value: "n_polla", sortable: false },
            { text: "Cliente", value: "cliente", sortable: false },
            { text: "N°doc", value: "n_doc", sortable: false },
            { text: "Metodo de Pago", value: "m_pago", sortable: false },
            { text: "Price Polla", value: "p_polla", sortable: false },
            { text: "Cod. Prom", value: "cod_prom", sortable: false },
            { text: "Descuento", value: "discount", sortable: false },
            { text: "Subtotal", value: "subtotal", sortable: false },
            { text: "Total", value: "total", sortable: false },
        ],
        page: 1,
        totalPages: 0,
        pageSizes: [10, 20, 30],
        pageSize: 10,
        //   loading: false
    }),
    computed: {
        ...mapState({
            arrayPurchases: (state) => state.purchase.arrayPurchases,
            fetchingData: (state) => state.fetchingData,
        }),
        // text() {
        //     return this.$route.query.text;
        // },
    },
    methods: {
        ...mapActions(["fetchPurchases"]),
        getRequestParams(page, pageSize) {
            let params = {};

            if (this.text) {
                params["search"] = this.text;
            }
            if (page) {
                params["page"] = page - 1;
            }
            if (pageSize) {
                params["per_page"] = pageSize;
            }
            return params;
        },
        handlePageChange(value) {
            this.page = value;
            this.getFetch();
            //this.retrieveTutorials();
        },

        handlePageSizeChange(size) {
            this.pageSize = size;
            this.page = 1;
            this.getFetch();
            //this.retrieveTutorials();
        },
        getFetch() {
            const params = this.getRequestParams(this.page, this.pageSize);
            this.fetchPurchases({ params: params, polla_type_id: 1 });
        },
        // getCurrentYear() {
        //   return new Date().getFullYear();
        // },
    },
    created() {
        this.getFetch();
    },
};
</script>

<style>
</style>