<template>
    <v-container fluid>
        <v-row>
            <v-col col="12" md="12" lg="12" outlined>
                <v-card class="pa-4 rounded-xl">
                    <v-card-title class="justify-left d-flex flex-row">
                        <p>Ventas</p>
                        <v-spacer></v-spacer>
                        <v-spacer></v-spacer>
                        <v-text-field  class="mt-6" label="Buscar" v-model="search" outlined dense
                         prepend-inner-icon="mdi-magnify" 
                        ></v-text-field>
                        <v-btn class="ma-3 rounded-lg text-capitalize" dense color="primary" @click="exportar()">
                            Exportar Reporte
                        </v-btn>

                    </v-card-title>
                    <v-card-text>
                        <v-container fluid>
                            <v-row>
                                <v-col cols="12" md="12" lg="12">
                                    <table-ventas :text="search"></table-ventas>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                </v-card>
            </v-col>

        </v-row>
    </v-container>
</template>

<script>
import TableVentas from "@/components/tables/TableVentas.vue";
import { mapActions } from "vuex";
export default {
    name: "polla-callejera-ventas",
    components: { TableVentas },
    data: () => ({
        search: ''
    }),
    methods: {
         ...mapActions(["exportPruchases"]),
        exportar(){
            this.exportPruchases({
                params : {
                    search : this.search
                },
                polla_type_id : 1
            })
        }
    }
};
</script>

<style>
</style>